import React from "react";

const ComponentLoader = () => {
    return (
        <div className="mb-3">
            <div className="progress">
                <div className="progress-bar progress-bar-indeterminate bg-cyan" />
            </div>

            <div className="page-wrapper">
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-cards">
                            <div className="col-12">
                                <div className="row row-cards">
                                    <div className="col-4">
                                        <div className="card">
                                            <div className="card-body text-end placeholder-glow">
                                                <div className="placeholder col-9 mb-3" />
                                                <div className="placeholder placeholder-xs col-10" />
                                                <div className="placeholder placeholder-xs col-12" />
                                                <div className="placeholder placeholder-xs col-11" />
                                                <div className="placeholder placeholder-xs col-8" />
                                                <div className="placeholder placeholder-xs col-10" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="card">
                                            <div className="card-body text-end placeholder-glow">
                                                <div className="placeholder col-9 mb-3" />
                                                <div className="placeholder placeholder-xs col-10" />
                                                <div className="placeholder placeholder-xs col-12" />
                                                <div className="placeholder placeholder-xs col-11" />
                                                <div className="placeholder placeholder-xs col-8" />
                                                <div className="placeholder placeholder-xs col-10" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="card">
                                            <div className="card-body text-end placeholder-glow">
                                                <div className="placeholder col-9 mb-3" />
                                                <div className="placeholder placeholder-xs col-10" />
                                                <div className="placeholder placeholder-xs col-12" />
                                                <div className="placeholder placeholder-xs col-11" />
                                                <div className="placeholder placeholder-xs col-8" />
                                                <div className="placeholder placeholder-xs col-10" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default ComponentLoader;