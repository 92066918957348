import React from "react";

const Loader = () => {
    return (
        <div className="mb-3">
            <div className="progress">
                <div className="progress-bar progress-bar-indeterminate bg-cyan" />
            </div>

            <div className="page-wrapper">
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-cards">
                            <div className="col-6">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body py-5 text-center">
                                                <div>
                                                    <div className="avatar avatar-rounded avatar-lg placeholder mb-3" />
                                                </div>
                                                <div className="mt w-75 mx-auto">
                                                    <div className="placeholder col-9 mb-3" />
                                                    <div className="placeholder placeholder-xs col-10" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body text-end placeholder-glow">
                                                <div className="placeholder col-9 mb-3" />
                                                <div className="placeholder placeholder-xs col-10" />
                                                <div className="placeholder placeholder-xs col-12" />
                                                <div className="placeholder placeholder-xs col-11" />
                                                <div className="placeholder placeholder-xs col-8" />
                                                <div className="placeholder placeholder-xs col-10" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                            <div className="col-6">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body text-end placeholder-glow">
                                                <div className="placeholder col-9 mb-3" />
                                                <div className="placeholder placeholder-xs col-10" />
                                                <div className="placeholder placeholder-xs col-12" />
                                                <div className="placeholder placeholder-xs col-11" />
                                                <div className="placeholder placeholder-xs col-8" />
                                                <div className="placeholder placeholder-xs col-10" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="card">
                                            <ul className="list-group list-group-flush placeholder-glow">
                                                <li className="list-group-item">
                                                    <div className="row align-items-center">
                                                        <div className="col-auto">
                                                            <div className="avatar avatar-rounded placeholder" />
                                                        </div>
                                                        <div className="col-7">
                                                            <div className="placeholder placeholder-xs col-9" />
                                                            <div className="placeholder placeholder-xs col-7" />
                                                        </div>
                                                        <div className="col-2 ms-auto text-end">
                                                            <div className="placeholder placeholder-xs col-8" />
                                                            <div className="placeholder placeholder-xs col-10" />
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item">
                                                    <div className="row align-items-center">
                                                        <div className="col-auto">
                                                            <div className="avatar avatar-rounded placeholder" />
                                                        </div>
                                                        <div className="col-7">
                                                            <div className="placeholder placeholder-xs col-9" />
                                                            <div className="placeholder placeholder-xs col-7" />
                                                        </div>
                                                        <div className="col-2 ms-auto text-end">
                                                            <div className="placeholder placeholder-xs col-8" />
                                                            <div className="placeholder placeholder-xs col-10" />
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item">
                                                    <div className="row align-items-center">
                                                        <div className="col-auto">
                                                            <div className="avatar avatar-rounded placeholder" />
                                                        </div>
                                                        <div className="col-7">
                                                            <div className="placeholder placeholder-xs col-9" />
                                                            <div className="placeholder placeholder-xs col-7" />
                                                        </div>
                                                        <div className="col-2 ms-auto text-end">
                                                            <div className="placeholder placeholder-xs col-8" />
                                                            <div className="placeholder placeholder-xs col-10" />
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item">
                                                    <div className="row align-items-center">
                                                        <div className="col-auto">
                                                            <div className="avatar avatar-rounded placeholder" />
                                                        </div>
                                                        <div className="col-7">
                                                            <div className="placeholder placeholder-xs col-9" />
                                                            <div className="placeholder placeholder-xs col-7" />
                                                        </div>
                                                        <div className="col-2 ms-auto text-end">
                                                            <div className="placeholder placeholder-xs col-8" />
                                                            <div className="placeholder placeholder-xs col-10" />
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default Loader;